<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right">
            <div class="logo">
              <h4>Global NDA</h4>
            </div>

            <div class="auth-page-right-content text-center">
              <ThemeButton class="d-block" />
              <h6>Email Verified</h6>

              <div class="success-img">
                <img src="../../../assets/images/success.png" alt="" />
              </div>
              <p>Your Email has been verified.</p>
              <button
                type="button"
                class="auth-btn mt-2"
                @click="sendUserLogin(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Next</span>
              </button>
            </div>
            <div class="d-block d-md-none mt-5">
              <div class="circle-one">
                <img src="../../../assets/images/circle1.png" />
              </div>
              <div class="circle-two ">
                <img src="../../../assets/images/circle2.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeButton from "../../../components/Enterprise/Theme/ThemeButton.vue";
import { mapGetters, mapActions } from "vuex";
import AuthLeft from "../../../components/Enterprise/authleft/AuthLeft.vue";
import axios from "axios"

export default {
  name: "EnterpriseVerifiedEmail",

  components: {
    ThemeButton,
    AuthLeft,
  },

  data: function() {
    return {
      isLoadingArray: [],
      para: "All Set. Let’s get you logged in.",
      verifyCode: ""
    };
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    this.verifyEmail();
  },

  methods: {
    ...mapActions("auth", ["sendLoginRequest"]),

    sendUserLogin: function(index) {
      
      this.$set(this.isLoadingArray, index, true);
      setTimeout(() => {
        this.$set(this.isLoadingArray, index, false);
        this.$router.push({ name: "EnterpriseLogin" });
      }, 500);

      //   });
    },
    verifyEmail(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.verifyCode = urlParams.get("code");
      if (this.verifyCode != null) {
      axios
        .get(
          process.env.VUE_APP_API_URL + `verifyEmail?code=${this.verifyCode}`,
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            console.log("updated");
            localStorage.removeItem("emstatus");
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
      } 
    },
  },
};
</script>
